<!-- contact us start -->
<section class="section bg-contact-us" id="contact">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-5">
                <div class="title text-center mb-4">
                    <h5 class="small-title">Contact</h5>
                    <h3>Get in Touch</h3>
<!--                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</p>-->
                </div>
            </div>
        </div>
        <!-- row end -->
        <div class="row align-items-end">
           <div class="col-lg-6">
               <div class="contact-img d-none d-lg-block">
                    <img src="/assets/images/contactus-petronetwork.png" alt="" class="img-fluid mx-auto d-block">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card contact-form mb-lg-0">
                    <div class="custom-form p-5">

                        <div id="message"></div>
                        <form name="contact-form" id="contact-form" @submit.prevent="sendEmail">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="text-danger">
                                            {{ contactUs.name.errorMessage }}
                                        </div>
                                        <input name="name" id="name" type="text" class="form-control" placeholder="Enter your name..." v-model="contactUs.name.value">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <div class="text-danger">
                                            {{ contactUs.email.errorMessage }}
                                        </div>
                                        <input name="email" id="email" type="email" class="form-control" placeholder="Enter your email..." v-model="contactUs.email.value">
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <div class="text-danger">
                                            {{ contactUs.message.errorMessage }}
                                        </div>
                                        <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Enter your message..." v-model="contactUs.message.value"></textarea>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->

                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary btn-rounded" value="Send Message" :disabled="loading">
                                    <div id="simple-msg" class="text-success">
                                        {{successMessage}}
                                    </div>
                                    <div id="simple-msg" class="text-danger">
                                        {{errorMessage}}
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->
                        </form>
                    </div>
                    <!-- end custom-form -->

                </div>
            </div>

        </div>
        <!-- row end -->
    </div>
    <!-- container-fluid end -->
</section>
<!-- contact us end -->