(function (window) {
    "use strict";
    function defineResponseStatus() {
        var responseStatus = {};
        responseStatus.status = {
            failed: 0,
            successful: 1,
            successfulWithWarnings: 2
        };
        responseStatus.responseCode = {
            emailAlreadyExists: "SE.4"
        };
        return responseStatus;
    }
    //define globally if it doesn't already exist
    if (typeof (responseStatus) === "undefined") {
        window.responseStatus = defineResponseStatus();
    } else {
        console.log("responseStatus already defined.");
    }
})(window);