/* eslint-disable no-undef */
(function (window) {
	"use strict";
	function defineApiResponseHandlerHelper() {
		var apiResponseHandlerHelepr = {};
		apiResponseHandlerHelepr.displayResponseMessageBasedOnApiResponseStatus = function (apiResponseStatus, apiHttpResponseCode, failedMessage, doNotShowErrorMessage) {
			console.log("apiResponseStatus", apiResponseStatus);
			var origin = window.location.origin;
			var hasAlreadyShownError = false;
			console.log("hasAlreadyShownError", hasAlreadyShownError);

			$("#sessionExpiredModal").on("hidden.bs.modal", function () {
				window.location.replace(origin + "/Account/Login");
			});
			
			if(hasAlreadyShownError){
				return false;
			}
			if (apiResponseStatus == null || apiResponseStatus === undefined) {
				hasAlreadyShownError = true;
				//alert("Your session has expired. Please login again to continue");
				$("#sessionExpiredModal").modal("show");
				
				//window.location.replace(origin + "/Account/Login");
				return false;
			}
			if (apiResponseStatus !== responseStatus.status.successful && apiHttpResponseCode === httpResponseCode.code.unauthorized) {
				hasAlreadyShownError = true;
				//alert("Your session has expired. Please login again to continue");
				$("#sessionExpiredModal").modal("show");
				//window.location.replace(origin + "/Account/Login");
				return false;
			}
			if (apiResponseStatus != responseStatus.status.successful) {
				//failed
				console.log("doNotShowErrorMessage", doNotShowErrorMessage);
				if (!doNotShowErrorMessage) {
					//alert(failedMessage);
					document.getElementById("globalErrorModalErrorMessageDiv").innerHTML = failedMessage;
					$("#globalErrorModal").modal("show");
				}
				return false;
			}
			return true;
		};

		apiResponseHandlerHelepr.displaySessionExpiredMessage = function (httpStatus) {
			if(httpStatus === 302 || httpStatus === 401 || httpStatus === 403){
				var origin = window.location.origin;
				var hasAlreadyShownError = false;
				console.log("hasAlreadyShownError", hasAlreadyShownError);
				if(hasAlreadyShownError){
					return;
				}

				$("#sessionExpiredModal").on("hidden.bs.modal", function () {
					window.location.replace(origin + "/Account/Login");
				});

				$("#sessionExpiredModal").modal("show");

				hasAlreadyShownError = true;
			}
			else{
				//alert("Unexpected error occurred");
				document.getElementById("globalErrorModalErrorMessageDiv").innerHTML = "Unexpected error occurred";
				$("#globalErrorModal").modal("show");
			}
		};
		return apiResponseHandlerHelepr;
	}
	//define globally if it doesn't already exist
	if (typeof (apiResponseHandlerHelepr) === "undefined") {
		window.apiResponseHandlerHelepr = defineApiResponseHandlerHelper();
	} else {
		console.log("apiResponseHandlerHelepr already defined.");
	}
})(window);