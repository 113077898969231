import axios from "axios";
import "./response-status.js";
import "./helpers/apiResponseHandler-helper.js";
import "../Content/libs/bootstrap4.5.2/js/bootstrap.bundle.js";
import "../Content/js/landingPage/jquery.easing.min.js";
import "../Content/js/landingPage/scrollspy.min.js";
//import '../Content/js/landingPage/owl.carousel.min.js';
import "../Content/js/landingPage/app.js";

import "../Content/libs/bootstrap4.5.2/css/bootstrap.css";
//import "../Content/css/icons.min.css";
import "../Content/scss/icons.scss";
import "../Content/fonts/icomoon/style.css";
//import '../Content/css/app.min.css';
//import '../Content/css/landingPage/owl.carousel.css';
//import '../Content/css/landingPage/owl.theme.css';
//import "../Content/css/landingPage/owl.transitions.css";

import "../Content/css/landingPage/wizard.css";
import "../Content/scss/app.scss";
import "../Content/style.css";
//import "../Content/style.css";
import "../Content/css/landingPage/style.css";

import "./services/notification-service.js";

window.axios = axios;
import { createApp } from "vue";

import ContactUsForm from "./components/contactUsForm/ContactUsForm.vue";

let options = {
    data() {
        return {
        };
    },
    methods: {
    },
    components: {
    },
    mounted: function () {
    },
    watch: {
    }
};

const app = createApp(options);

app.component("contact-us-form", ContactUsForm);

if (document.getElementById("app")) {
    //load vue js options on specific pages
    // app.use(VueGoogleMaps, {
    // 	load: {
    // 		key: 'AIzaSyCGAH9fut53QSMCbZUDkwnzKENDFCRofEs',
    // 		// language: 'de',
    // 	},
    // }).mount("#app");
    app.mount("#app");
}