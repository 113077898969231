/* eslint-disable no-undef */
(function (window) {
    "use strict";
    function defineNotificationService() {
        let notificationService = {};
        notificationService.sendEmail = function (request){
            return axios.post("/api/NotificationService/SendEmail", request);
        };
        return notificationService;
    }
    //define globally if it doesn't already exist
    if (typeof (notificationService) === "undefined") {
        window.notificationService = defineNotificationService();
    } else {
        console.log("notificationService already defined.");
    }
})(window);