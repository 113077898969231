export default {
	name: "contact-us-form",
	data() {
		return {
			contactUs: {
				name:{
					value: null,
					hasError: false,
					errorMessage: null
				},
				email:{
					value: null,
					hasError: false,
					errorMessage: null
				},
				message:{
					value: null,
					hasError: false,
					errorMessage: null
				},
			},
			loading: false,
			successMessage: null,
			errorMessage: null,
		};
	},
	methods: {
		sendEmail(){
			this.loading = true;
			let request = {
				name: this.contactUs.name.value,
				email: this.contactUs.email.value,
				message: this.contactUs.message.value
			};
			notificationService.sendEmail(request).then(
				successResponse => {
					console.log("successResponse", successResponse);
					let isSuccess = apiResponseHandlerHelepr.displayResponseMessageBasedOnApiResponseStatus(successResponse.data.responseStatus, successResponse.data.httpStatusCode, successResponse.data.message);
					if(isSuccess){
						if(successResponse.data.contactUsFormValidationResponse.valuesAreValid){
							this.successMessage = "Message sent successfully";
							this.clearContactUsModal();
						}
						else{
							this.contactUs.name.hasError = successResponse.data.contactUsFormValidationResponse.nameValidationResponse.hasError;
							this.contactUs.name.errorMessage = successResponse.data.contactUsFormValidationResponse.nameValidationResponse.message;
							
							this.contactUs.email.hasError = successResponse.data.contactUsFormValidationResponse.emailValidationResponse.hasError;
							this.contactUs.email.errorMessage = successResponse.data.contactUsFormValidationResponse.emailValidationResponse.message;
							
							this.contactUs.message.hasError = successResponse.data.contactUsFormValidationResponse.messageValidationResponse.hasError;
							this.contactUs.message.errorMessage = successResponse.data.contactUsFormValidationResponse.messageValidationResponse.message;
						}
					}
					else{
						this.errorMessage = "Failed to send message";
					}
					this.loading = false;
				},
				errorResponse => {
					console.log("errorResponse", errorResponse);
					this.loading = false;
					apiResponseHandlerHelepr.displaySessionExpiredMessage(errorResponse.response.status);
				}
			);
		},
		clearContactUsModal(){
			this.contactUs = {
				name:{
					value: null,
					hasError: false,
					errorMessage: null
				},
				email:{
					value: null,
					hasError: false,
					errorMessage: null
				},
				message:{
					value: null,
					hasError: false,
					errorMessage: null
				},
			};
		}
	},
	mounted: function () {
	}
};